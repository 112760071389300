import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imagenPath',
  standalone: true
})
export class ImagenPathPipe implements PipeTransform {

  transform(user: any): string {

      let baseUrl = environment.baseUrl;
      
      if (user && user.Ruta_Imagen  && user.Ruta_Imagen  !== '') {
          
        const fileName = user.Ruta_Imagen ;
  
          let serverURL = null;
  
          if(baseUrl.includes('http://localhost') && !user.Ruta_Imagen.startsWith('https://congregatio.info/')){
            serverURL = `${baseUrl}profilePicture/`;
            return `${serverURL}${fileName}`;
          }

          if(user.Ruta_Imagen.startsWith('https://congregatio.info/')){
            return user.Ruta_Imagen
          }else{
            serverURL = 'https://arcanjosaorafael.org/profilePicture/';
          }

      
          console.log(`${serverURL}${fileName}`);
          return `${serverURL}${fileName}`;
      } else {
        return 'assets/images/no-image.jpg';
    }}
  
  }