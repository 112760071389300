import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ErrorService } from 'src/app/services/error.service';
import { ImagenPathPipe } from "../../../../pipe/imagen-path.pipe";
import { getDataSS } from 'src/app/storage';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { PropulsaoService } from 'src/app/services/propulsao.service';
import { AppState } from 'src/app/shared/redux/app.reducer';
import { Store } from '@ngrx/store';
import * as authActions from '../../../../shared/redux/auth.actions'
import { UserService } from 'src/app/services/user.service';
import { WorkedHours } from 'src/app/shared/interfaces/workedHours.interface';
import { StatisticsService } from 'src/app/services/statistics.service';


interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule, ImagenPathPipe],
})
export class HeaderComponent implements OnInit {

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();



  showFiller = false;
  user: any;
  arrNotifications: any []=[];
  numberNotification : any;
  phone : boolean = false;
  emptyWorkedHourNotification : boolean = false;


  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  constructor(
              private vsidenav: CoreService,
              public dialog: MatDialog,
              private translate: TranslateService,
              private errorService : ErrorService,
              private notificationService : NotificationService,
              private authService : AuthService,
              private propulsaoService : PropulsaoService,
              private userService : UserService,
              private statisticsService : StatisticsService,
              private store : Store<AppState>,
              private cdr: ChangeDetectorRef


  )
  
  {

    (screen.width < 800) ? this.phone = true : this.phone = false;
   
    this.getInitData();
     
    translate.setDefaultLang('en');

  }

  private isCollapsed = false; // Estado inicial

  ngOnInit(): void {

    const user = getDataSS('user');
    if(user){
      this.getWorkedHoursByIdUser(user.iduser);
    }

    this.userService.realoadWorkedHours$.subscribe( (emitted)=> {if(emitted) this.getWorkedHoursByIdUser(user.iduser)} )

    this.authService.successLoginAs$.subscribe((emitted)=> { if(emitted){this.getInitData()}})
    this.getInitData();

    // si se dicpara del worked hour component tengo q volver a recargar el badge
   this.store.select('auth').subscribe(
      ({workedHours})=>{
        if(workedHours){
          if(workedHours.length > 0){
            this.emptyWorkedHourNotification = false;
            workedHours.forEach((item: { workedHours: any; }) =>{
              if(!item.workedHours){
                this.emptyWorkedHourNotification = true;
              }
            })
          }
        }
      }
    )};

    getWorkedHoursByIdUser(id: string) {
      this.statisticsService.getWorkedHoursByIdUser(id).subscribe(({ success, workedHours }) => {
        if (success) {

          this.store.dispatch(authActions.addWorkedHours({ workedHours: [...workedHours] }));
          workedHours.forEach((item: { workedHours: any; }) =>{
            if(!item.workedHours){
              this.emptyWorkedHourNotification = true;
            }
          })
          

          
        }
      });
    }


    // hice esto para manejar el logo del branding (sidebar) para q desaparezca si se contrae mucho

    emitToggleCollapsed(){
      this.isCollapsed = !this.isCollapsed; // Alterna entre true y false
      this.vsidenav.toggleCollapsed$.emit(this.isCollapsed); // Emite el nuevo estado
    }


getInitData(){
      const user = getDataSS('user');
      if(user){
        this.user = user;
        this.setPropulsao(user.iduser);
        this.getInitPreferences(user.iduser);
      }

      if(this.user && this.user.role === 'user'){
        this.notificationService.getUserFileNotifications().subscribe( 
          ({success, notifications})=>{
            if(success){
                this.arrNotifications = notifications;
            }
          });
      }
}

getInitPreferences( id:string ){
  
  this.userService.getUserPreferences(id).subscribe(
    ( {success, preferences} )=>{
      if(success){
        this.store.dispatch(authActions.addUserPreferences({preferences}))
      }
    })

}

setPropulsao( user:any ){
  this.propulsaoService.getPropulsaoByUserId(user).subscribe(
    ({ success, propulsao })=>{
      if(success){
        this.store.dispatch(authActions.setPropulsao( {propulsao} ))
      }
    })
}



markAsReadFileNotificationById( notification:any ){
  const id = notification.idfile_user_notification;
  this.notificationService.markAsReadFileNotificationById(id).subscribe( 
    ({success, notifications})=>{
      if(success){
          this.arrNotifications = notifications;
          this.getInitData();
      }
    });
}

bulkMarkNotificationRead(){
  this.notificationService.bulkFilesMarkNotificationAsRead().subscribe(
    ( {success} )=>{
      if(success){
        this.getInitData();
      }
    });
}


  deleteNotificationById(notification:any){

    this.notificationService.deleteNotificationById(notification.idnotification).subscribe(
      ( {success} )=>{
        if(success){
          this.getInitData();
        }
      })
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  logout(){
    this.errorService.logout();
   }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-inbox.svg',
      title: 'My Inbox',
      subtitle: 'Messages & Email',
      link: '/apps/email/inbox',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-tasks.svg',
      title: 'My Tasks',
      subtitle: 'To-do and Daily Tasks',
      link: '/apps/taskboard',
    },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/side-login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule
  ],
  templateUrl: 'search-dialog.component.html',
})

export class AppSearchDialogComponent   {
  
  searchText: string = '';
  navItems = navItems;
  
  navItemsData = navItems.filter((navitem) => navitem.displayName);
  
  // filtered = this.navItemsData.find((obj) => {
    //   return obj.displayName == this.searchinput;
    // });


    

  


}
