import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

const MODULES = [CommonModule, MaterialModule];

@Component({
  selector: 'app-validate-email',
  standalone: true,
  imports: [],
  templateUrl: './validate-email.component.html',
  styleUrl: './validate-email.component.scss'
})
export class ValidateEmailComponent {

  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);
  public toastr = inject(ToastrService);
  public router = inject(Router);

  code:string = '';
  email:string = '';
  success: boolean = false;

  constructor() 
  { 
        this.activatedRoute.params.subscribe(
        ({code})=>{ this.getCodes(code) });
 }

  ngOnInit(): void {

    
  }

  getCodes(codes: string) {

    if (codes.length >= 50) {
      this.code = codes.slice(-50); 
      this.email = codes.slice(0, -50).trim();
    } else {
      // Manejar el caso en el que la cadena no tenga al menos 12 caracteres
      console.error('La cadena no tiene al menos 50 caracteres');
    }
    console.log(this.code, this.email);
    if(this.code !== '' && this.email !== ''){
      console.log(this.code);
      this.validateEmail();
    }
  }
  validateEmail(){

    this.success = false;

    const body = {email: this.email, code: this.code}
    
    this.authService.validateEmail(body).subscribe(
      ( {success} )=>{
        if(success){
          this.openSwalSuccess();
        }
      })
    }

//  close(){
//   setTimeout(()=>{ window.close(); },700)
  
//  }   

 openSwalSuccess() {
  Swal.fire({
    title: "Sua conta foi verificada com sucesso!",
    text: "Em breve, você receberá um e-mail para concluir o processo de registro",
    icon: "success"
  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigateByUrl('/login')
    }
  });
}

openSwalError(){
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Link de validação errado!",
  });
}


  
}

