import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [

    {
      navCap: 'Admin',
    },
  
    {
      displayName: 'Configuração',
      iconName: 'settings',
      
      children: [
  
        {
          childName: 'Envío de arquivos',
          route: '/painel/arquivos',
        },
        {
          childName: 'Alarmes',
          route: '/painel/alarmes',
        },
        {
          childName: 'Próximos alarmes',
          route: '/painel/proximos-alarmes',
        },
        
        {
          childName: 'Segmentações',
          children: [
            {
              childrenName: 'Tratamentos',
              route: '/painel/tratamento',
            },
            {
              childrenName: 'Profissões',
              route: '/painel/profissao',
            },
            {
              childrenName: 'E-mails',
              route: '/painel/segmentacao-de-emails',
            },
            {
              childrenName: 'Telefones',
              route: '/painel/segmentacao-de-telefone',
            },
            {
              childrenName: 'Endereços',
              route: '/painel/segmentacao-de-enderecos',
            },
            {
              childrenName: 'Relaçãos',
              route: '/painel/segmentacao-de-relacao',
            },
            {
              childrenName: 'Estado Civil',
              route: '/painel/estado-civil',
            }
          ]
          
        },
        {
          childName: 'Congreatio',
          route: '/painel/congregatio',
        },
        {
          childName: 'Cidades - Dioceses',
          children: [
            {
              childrenName: 'Cidades',
              route: '/painel/cidades',
            },
            {
              childrenName: 'Dioceses',
              route: '/painel/dioceses',
            },
          ]
        },
        {
          childName: 'Fontes - Resultados',
          children: [
            {
              childrenName: 'Fontes',
              route: '/painel/fontes',
            },
            {
              childrenName: 'Resultados',
              route: '/painel/resultados',
            },
          ]
        },
        {
          childName: 'Bancos',
          children: [
            {
              childrenName: 'Débito Automático',
              route: '/painel/debito-automatico',
            },
            {
              childrenName: 'Cartões de crédito',
              route: '/painel/cartoes-de-credito',
            },
            {
              childrenName: 'Convênios',
              route: '/painel/convenios-bancarios',
            },
          ]
        },
        {
          childName: 'Associações',
          route: '/painel/associacao',
        },
        {
          childName: 'Unidades',
          route: '/painel/unidades',
        },
        {
          childName: 'Cadastro',
          route: '/painel/codigos-cad',
        },
        {
          childName: 'Propulsões',
          children: [
            {
              childrenName: 'Propulsões',
              route: '/painel/propulsoes',
            },
            {
              childrenName: 'Moedas',
              route: '/painel/moedas',
            },
          ]
        },
        {
          childName: 'Calendário',
          route: '/painel/calendario',
        },
        {
          childName: 'Imagens de fundo',
          route: '/painel/images-de-fundo',
        },
    
    
      ]
      
    },

    
    {
      navCap: 'Usuários',
    },

    {
      childName: 'Usuários',
      iconName: 'user-plus',

      children: [
       
        {
          childrenName: 'Usuários',
          route: '/painel/usuarios',
          
        },
        {
          childrenName: 'Duplas',
          route: '/painel/duplas',
        },

        {
          childrenName: 'Grupos',
          route: '/painel/grupos',
        },
      ],
  
      },
   
  
 
];


//solo los users

export const navItemsUser: any[] = [

  {
    navCap: 'Painel',
  },

    {
    displayName: 'Usuário',
    iconName: 'user-plus',

    children: [
      {
        childName: 'Arquivos',
        route: '/painel/arquivos-de-usuário',
      },

      {
        childName: 'Visitas',
        route: '/painel/malote',
      },
      {
        childName: 'Ferramentas',
        children: [
          {
            childrenName: 'Busca',
            route: '/painel/usuarios-cad',
          }
        ]
       },

      {
        childName: 'Horas trabalhadas',
        route: '/painel/horas-trabalhadas',
      },

      {
        childName: 'Perfil',
        route: '/painel/perfil-usuario/:id',
      },
     
    ],

    },


  {
      displayName: 'Configurações',
      iconName: 'settings',
      route: '/painel/usuarios-configuracaos',
      
      // children: [
  
      //   {
      //     childName: 'Envío de arquivos',
      //   },
      // ]
    }
 
   
  
 
];
