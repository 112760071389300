@if(pageInfo?.['title'] != 'Analytical' && pageInfo?.['title'] != 'eCommerce'){

<div class="p-y-30 p-x-24 m-b-30 " >
<!-- <div class="bg-light-primary rounded p-y-30 p-x-24 m-b-30 overflow-hidden customCard " > -->

  
  <div class="row">
    <div class="d-flex justify-content-start align-items-center w-100" >
      
      <div class="d-flex justify-content-center align-items-center " [ngClass]="back ? 'iconSelected':'iconDeselected' " (click)="goBack()" *ngIf="showBackArrow">
        <span class="material-symbols-outlined  cursor">arrow_back_ios_new</span>
      </div> &nbsp;
      <span class="d-flex justify-content-start align-items-center fs-15 ml-20 " style="font-family: 'poppins-light'; font-weight: 600;">
      {{ pageInfo?.['title'] }}
      </span>

      <div class="d-flex align-items-center overflow-hidden">
        <ul class="breadcrumb">
          @for (url of pageInfo?.['urls']; track url.url;let index = $index, last = $last ) { @if(!last){

          <!-- <li class="breadcrumb-item" [routerLink]="url.url">
            <a [routerLink]="url.url">{{ url.title }}</a>
          </li> -->
          }@else {
          <li class="breadcrumb-item">
            <i-tabler name="circle-filled" class="icon-8"></i-tabler>
          </li>
          <li class="breadcrumb-item active">{{ url.title }}</li>
          } }
        </ul>
      </div>
    </div>
    <!-- <div class="col-sm-4 text-right ">
      <div class="breadcrumb-icon align-items-center ">
        <img src="/assets/images/breadcrumb/user.png" alt="breadcrumb" width="80"  *ngIf="pageInfo?.['title']=== 'Usuários' " class="mt-10"/>
        <img src="/assets/images/breadcrumb/user-speaking.png" alt="breadcrumb" width="100"  *ngIf="pageInfo?.['title']=== 'Congressos' " style="margin-bottom: 60px;"/>
      </div>
    </div> -->
  </div>
</div>
}
