
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports:[RouterModule, CommonModule],
  template: `
    <div class="branding">
      @if(options.theme === 'light') {
      <a class="cursor" *ngIf="!hideImg">
        <img
          src="./assets/images/logos/logo_white_background.png"
          class="align-middle m-2"
          alt="logo"
          width="100%"
          routerLink="/painel"
          
        />
      </a>
      } @if(options.theme === 'dark') {
      <a  class="cursor">
        <img
          src="./assets/images/logos/logo_white_background.png"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      }
    </div>
  `,
})
export class BrandingComponent {

  hideImg: boolean= false;

  options = this.settings.getOptions();
  
  constructor(  private settings: CoreService,
    
  ) 
  {
    this.settings.toggleCollapsed$.subscribe((emmited)=>{
      if(emmited){
        this.hideImg = true;
      }else{
        this.hideImg = false;
      }
    })

  }
}
