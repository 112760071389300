import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {


  private baseUrl = environment.baseUrl;

  private http = inject(HttpClient);


  constructor( ) {

   }
   

   addWorkedHoursToADay(id:string,  body:any  ){
    return this.http.patch<any>(`${this.baseUrl}api/statistics/addWorkedHoursToADay/${id}`, body) 
    .pipe(
      tap( ( res) =>{ console.log("from addWorkedHoursToADay service: ", res)}),            
      map( res => res )
    )
  }

  
  getWorkedHoursByIdUser( id:any ){
  return this.http.get<any>(`${this.baseUrl}api/statistics/getWorkedHoursByIdUser/${id}` ) 
  .pipe(
    tap( ( res) =>{ console.log("from getWorkedHoursByIdUser service: ", res)}),            
    map( res => res )
  )
}

getWorkedHoursByMonthAndYear( id:any, date:string ){
  
  const params = new HttpParams()
  .set('iduser', id)
  .set('date', date)

  return this.http.get<any>(`${this.baseUrl}api/statistics/getWorkedHoursByMonthAndYear`, {params} ) 
  .pipe(
    tap( ( res) =>{ console.log("from getWorkedHoursByMonthAndYear service: ", res)}),            
    map( res => res )
  )
}

}
