import { Component, HostBinding, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';
import { NavService } from '../../../../../services/nav.service';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { getDataSS } from 'src/app/storage';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/redux/app.reducer';
import { filter } from 'rxjs';

@Component({
  selector: 'app-nav-item',
  standalone: true,
  imports: [TranslateModule, TablerIconsModule, MaterialModule, CommonModule],
  templateUrl: './nav-item.component.html',
  styleUrls: [],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class AppNavItemComponent implements OnChanges, OnChanges {
  @Output() toggleMobileLink: any = new EventEmitter<void>();
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  expanded: any = false;
  disabled: any = false;
  twoLines: any = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem | any;
  @Input() depth: any;
  user:any
  items: any;

  constructor(
              public navService: NavService, 
              public router: Router,
              private store : Store <AppState>,

            ) 
  
  {
    if (this.depth === undefined) {
      this.depth = 0;
    }

    const user = getDataSS('user');
    if(user){
      this.user = user;
    }

  }

  
  ngOnChanges() {
    this.setUserSideNavbar(this.item)
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
    //scroll
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (!this.expanded){
    if (window.innerWidth < 1024) {
      this.notify.emit();
    }
  }
  }

  onSubItemSelected(item: NavItem) {
    if (!item.children || !item.children.length){
      if (this.expanded && window.innerWidth < 1024) {
        this.notify.emit();
      }
    }
  }

  setUserSideNavbar(item: any) {
    
    
    if(this.user){
      if(this.user.role === "user") {
        if (item.navCap === "Admin" || item.displayName === 'Configuração' ||  item.childName === 'Usuários' ||item.navCap === "Usuários" || item.displayName === 'Usuários') {
          return this.items= null;
        }
      }else{
          if (item.navCap === "Painel" || item.childName === 'Usuário' || item.displayName === 'Usuário') {
            return  this.items= null;
          }
        }
        return this.items = item;
     }

    }
  
}
