import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { filter, take } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { AssociationService } from 'src/app/services/association.service';
import { AuthService } from 'src/app/services/auth.service';
import { CongregatioService } from 'src/app/services/congregatio.service';
import { ErrorService } from 'src/app/services/error.service';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/shared/redux/app.reducer';
import { RevenueUpdatesComponent } from "../../eliminar-despues/revenue-updates/revenue-updates.component";
import * as authActions from '../../../shared/redux/auth.actions';
import { StatisticsService } from 'src/app/services/statistics.service';

const MODULES = [CommonModule, MaterialModule, RouterModule, RevenueUpdatesComponent];

interface topcards {
  id: number;
  img: string;
  color: string;
  title: string;
  subtitle: string;
}

@Component({
  selector: 'app-dashboard1',
  standalone: true,
  imports: [ MODULES ],
  templateUrl: './dashboard1.component.html',
    styleUrl: './dashboard1.component.scss'
})
export class AppDashboard1Component {


  private errorService = inject(ErrorService);
  private store = inject(Store <AppState>);
  private authService = inject( AuthService);
  private userService = inject(UserService);
  private congregatioService = inject(CongregatioService);
  private associationService = inject(AssociationService);
  private router = inject( Router );
  // private cookieService = inject(CookieService);
  // private statisticsService = inject(StatisticsService);

  user:any;
  isLoading : boolean = false;
  reqRegister : any []=[];
  reqLength: number = 0;
  unitTimeStamp : any;
  verifiedUserCount : any = 0;
  unverifiedUserCount : any = 0;
  webmasterUserCount : any = 0;
  adminUserCount : any = 0;
  congregatio : any;



  constructor(
         
             

  ){

  }

  ngOnInit(): void {

    this.errorService.closeIsLoading$.subscribe((emmited)=>{if(emmited){this.isLoading = false}});
  
  
    this.store.select('auth')
    .pipe(
      filter( ({user})=>  user !== null && user !== undefined),
      take(1)
    ).subscribe(
      ( {user} )=>{
        this.isLoading = false;
        this.user = user;
        
        if( user.role !== 'user'){
          this.setUserLogs(user!.email);
          this.getRequestedPermissions();
          this.getUsersQuantities();
          this.getCongregatioTimestamp();
          this.getUnitsTimestamp();
        }
      
  
      })
      
    
    }


    setUserLogs( email:string ){
      if(email && email !== ''){
        const body = { email}
        this.authService.setUserLogs(body).subscribe();
      } 
    }


  
    getRequestedPermissions(){
  
          this.authService.getRequestedPermissions().subscribe(
            ({success, requests})=>{
              if(success){
                  this.reqRegister = requests;
                  this.reqLength = requests.length;
              }
            })
   }
  
  getUsersQuantities(){
    this.userService.getAllUsersQuantity().subscribe(
      ( {success, verifiedUserCount, unverifiedUserCount, webmasterUsersCount, adminUsersCount } )=>{
          if(success){
            this.verifiedUserCount =  verifiedUserCount;
            this.unverifiedUserCount = unverifiedUserCount;
            this.webmasterUserCount = webmasterUsersCount;
            this.adminUserCount = adminUsersCount;
            this.initializeTopCards();
          }
  
      })
  
  }
  
  getUnitsTimestamp(){
  
    this.associationService.getUnitsTimestamp().subscribe(
      ( {success, units} )=>{
        if(success){
          this.unitTimeStamp = units;
        }
      }
      )
  }

 topcards: topcards[] = [];

 navigateToPanel( item:string ){

  switch (item) {
    case 'Usuários ativos':
                this.router.navigateByUrl('/painel/usuarios-ativos')
      break;
  
    case 'Usuários desativados':
                this.router.navigateByUrl('/painel/usuarios-desativos')
      break;

    case 'Webmasters':
                this.router.navigateByUrl('/painel/webmasters')
      break;
  
    case 'Administradores':
                this.router.navigateByUrl('/painel/administradores')
      break;
  
    default:
      break;
  }

 }

  initializeTopCards() {

   this.topcards = [
      {
        id: 1,
        color: 'primary',
        img: '/assets/images/svgs/icon-user-male.svg',
        title: 'Usuários ativos',
        subtitle: this.verifiedUserCount,
      },
      {
        id: 2,
        color: 'warning',
        img: '/assets/images/svgs/icon-briefcase.svg',
        title: 'Usuários desativados',
        subtitle: this.unverifiedUserCount,
      },
      {
        id: 3,
        color: 'accent',
        img: '/assets/images/svgs/icon-mailbox.svg',
        title: 'Webmasters',
        subtitle: this.webmasterUserCount,
      },
      {
        id: 4,
        color: 'error',
        img: '/assets/images/svgs/icon-favorites.svg',
        title: 'Administradores',
        subtitle: this.adminUserCount,
      },
      
    ];
  }

  
  
  
  getCongregatioTimestamp(){
    this.congregatioService.getCongregatioTimestamp().subscribe(
      ( {success, congregatio} )=>{
        if(success){
          this.congregatio = congregatio;
  
        }
      })
  }  
}
