<div class="blank-layout-container justify-content-center">
  <div class="row">
    <div class="col-12 text-center">
      <img src="/assets/images/backgrounds/errorimg.svg" alt="error-bg" />
      <h2 class="auth-title f-w-600">Opps!!!</h2>
      <h6 class="f-s-20 f-w-600 m-b-30">Esta página que você procura não foi encontrada.</h6>
      <a mat-flat-button color="primary" [routerLink]="['/login']">Volte para fazer login</a>
    </div>
  </div>
</div>
