<mat-card class="cardWithShadow">
    <mat-card-header>
      <div class="d-flex w-100">
        <div>
          <mat-card-title>Estatísticas diárias</mat-card-title>
          <mat-card-subtitle class="mat-body-1"
            >Visão geral das doações</mat-card-subtitle
          >


        </div>
        <div class="m-l-auto">

          <mat-form-field appearance="outline">
            <mat-label>Mês e Ano</mat-label>
            <input matInput 
                  [matDatepicker]="dp" 
                  [formControl]="date"
                  readonly="true">
            <mat-hint class="font">Clique no ícone para selecionar o mês e o ano</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (monthSelected)="setMonthAndYear($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>


        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="row">

        <div class="col-12">

            <apx-chart
              [series]="revenueChart.series"
              [dataLabels]="revenueChart.dataLabels"
              [chart]="revenueChart.chart"
              [legend]="revenueChart.legend"
              [xaxis]="revenueChart.xaxis"
              [yaxis]="revenueChart.yaxis"
              [grid]="revenueChart.grid"
              [stroke]="revenueChart.stroke"
              [tooltip]="revenueChart.tooltip"
              [plotOptions]="revenueChart.plotOptions"
            ></apx-chart>



        </div>

        <div class="d-flex justify-content-start  pd-10">
          <div class="d-flex align-items-center m-t-24">
            <span
              class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
            >
              <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
            </span>
  
            <div class="">
              <h5 class="mat-headline-5 m-b-4">{{propulsao?.acronym}} {{monthlyAmount.toFixed(2)}} </h5>
              <span class="mat-subtitle-2">Total Doações este mês</span>
            </div>
          </div>

          &nbsp;
  
          <div class="d-flex m-t-32 ml-30">
            <i-tabler
              name="clock"
              class="text-primary icon-24"
            ></i-tabler>
            <div class="m-l-12">
              <span class="mat-subtitle-2">Horas trabalhadas este mês</span>
              <h3 class="mat-headline-6 m-t-4">{{ monthlyWorkedHours | decimalToTime }} hs</h3>
            </div>
          </div>
  
  
          <!-- <button mat-flat-button color="primary" class="d-block w-100 m-t-32">
            View Full Report
          </button> -->
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  