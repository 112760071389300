

<form [formGroup]="myForm" class="font fs-10"  (ngSubmit)="onSave()">

    <div class="d-flex justify-content-between align-items-center pd-10" style="border-bottom: 2px solid rgb(0, 98, 255);">
        <h1 mat-dialog-title style="font-family: poppins-regular;">Entre em contato conosco </h1>
        <span class="material-icons cursor " [ngClass]="backClose ? ' iconSelected':'iconDeselected' " (click)="closeModal()">close</span>
    </div>

    <mat-dialog-content style="padding: 20px; " [ngStyle]="phone ? {'width':'97vw'}:{'width':'600px'}">
        
        <div class="cardWithShadow w-100 animate__animated animate__fadeIn pd-10"  >

            <div class="" >
                <mat-label class="font f-w-600 m-b-8 d-block"> Nome Completo  <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="fullName"/>
                @if(f['fullName'].touched && f['fullName'].invalid) {
                    <mat-hint class="m-b-16 error-msg">
                    @if(f['fullName'].errors && f['fullName'].errors['required']) {
                    <div class="error">Dado obrigatorio.</div>
                    } 
                    </mat-hint>
                    }
                </mat-form-field>
            </div>

            <div class="mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block"> Ordem  <b style="color: red;">*</b> </mat-label>
                <div class="w-100 mt-10" >
                <select class="select" formControlName="headquarter" style="height: 42px !important; font-family: poppins-regular;" >
                    <option selected="selected"> </option>
                    <option> &nbsp; Ordem primeira</option>
                    <option> &nbsp; Ordem segunda</option>
                </select>
                <div *ngIf="validField('headquarter')" class="error w-100 text-end" > Dado obrigatório </div>
          
          </div>
            </div>

            <div class="mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block"> Digite seu email   <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="userEmail"/>
                </mat-form-field>
                <div *ngIf="validField('userEmail')" class="invalid fs-10" > Dado obrigatório / Não tem formato de e-mail </div>
            </div>

            <div class="mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Assunto <b style="color: red;">*</b></mat-label>
                <textarea  class="w-100 pd-10" formControlName="subject" ></textarea>
                @if(f['subject'].touched && f['subject'].invalid) {
                  @if(f['subject'].errors && f['subject'].errors['required']) {
                  <div class="error">Dado obrigatorio.</div>
                  } 
                  }
            </div>
    
          </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
       <button type="button" mat-dialog-close class="cancelButton">Cancelar</button> &nbsp;  &nbsp;
       <button type="submit" class="acceptButton" >Enviar</button> 
        </mat-dialog-actions>
  </form>
  
  
  <div class="loading-overlay" *ngIf="isLoading">
    <div class="spinner-grow " role="status" style="width: 50px; height: 50px;"  >
        <mat-spinner></mat-spinner>
    </div>
  </div> 
  
            
  