import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'bankLogoPath',
  standalone: true
})
export class BankLogoPipe implements PipeTransform {

  transform(bank: any) {
    
    let baseUrl = environment.baseUrl;

    const fileName = bank.filePath;
    let serverURL = null;

    if (bank && bank.filePath && bank.filePath !== '') {

      if(baseUrl.includes('http://localhost') ){
        serverURL = `${baseUrl}bankAccount/`;
        return `${serverURL}${fileName}`;
      }

       serverURL = 'https://arcanjosaorafael.org/bankAccount/';

        console.log(`${serverURL}${fileName}`);
        return `${serverURL}${fileName}`;
    } else {

      return 'assets/images/no-image.jpg';
    }
  }
}

