


<mat-toolbar class="topbar">

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit(); emitToggleCollapsed() "
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }
  <span class="font ml-10" style="font-size: 0.8em !important;"> Propulsao: &nbsp; {{user?.propulsao_name}} </span>


  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button> -->


  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileFilterNav.emit()"
    class="d-flex d-lg-none justify-content-center"
  >
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>



 

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications" style="margin-right: 10px;" >
    <i-tabler class="d-flex" 
              name="bell" 
              [matBadge]="emptyWorkedHourNotification ? arrNotifications.length + 1 : arrNotifications.length" 
              matBadgeColor="primary" >
    </i-tabler>
  </button>


  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">

    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notificaçãoes</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12" > {{emptyWorkedHourNotification ? arrNotifications.length + 1 : arrNotifications.length}} novo</span
        >
      </span>
    </div>

    <mat-divider></mat-divider>

    <ng-container *ngIf=" arrNotifications.length > 0 || emptyWorkedHourNotification" >

    <div class="d-flex justify-content-between align-items-center " style="padding: 10px;" *ngFor="let notif of arrNotifications">
   
        <div class="d-flex  align-items-center " style="padding: 10px;">

          <div class="font " style="margin-left: 10px;">
            <p class="text-start " style="width: 100%; margin: 0;"> você recebeu um novo pacote de arquivos</p>
            <div class="d-flex  justify-content-start align-items-start ">
              <span class="material-symbols-outlined" style="transform: scale(0.85); color: gray; margin: 0;"> schedule </span> 
              <span style="font-size: 0.85em; color: gray;"> {{notif.timestamp | date }} </span> 
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end align-items-end ">
          <button mat-mini-fab  color="primary" style="color: white; transform: scale(0.65);" (click)="markAsReadFileNotificationById(notif)" routerLink="/painel/arquivos-de-usuário" >
            <mat-icon>visibility</mat-icon>
          </button>
        </div>
     </div>

     <div class="d-flex justify-content-between align-items-center " style="padding: 10px;" *ngIf="emptyWorkedHourNotification">
   
      <div class="d-flex  align-items-center " style="padding: 10px;">

        <div class="font " style="margin-left: 10px;">
          <p class="text-start " style="width: 100%; margin: 0;"> Você tem visitas sem horas trabalhadas atribuídas</p>
          <div class="d-flex  justify-content-start align-items-start ">
            <!-- <span class="material-symbols-outlined" style="transform: scale(0.85); color: gray; margin: 0;"> schedule </span> 
            <span style="font-size: 0.85em; color: gray;"> 12/6/55 </span>  -->
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-end ">
        <button mat-mini-fab  color="primary" style="color: white; transform: scale(0.65);" routerLink="/painel/horas-trabalhadas" >
          <mat-icon>visibility</mat-icon>
        </button>
      </div>
   </div>
    </ng-container>

    <!-- <ng-container *ngIf="arrNotifications && arrNotifications.length === 0" >
    <div class="d-flex justify-content-between align-items-center " style="padding: 10px;" >
      <span class="font fs-10" style="color: gray; margin-left: 20px;" > Sem notificaçãoes...</span>

    </div>
  </ng-container> -->
    
    <mat-divider></mat-divider>
    <div class="p-y-12 p-x-32" *ngIf="arrNotifications && arrNotifications.length > 1" >
      <button mat-stroked-button color="primary" class="w-100"  (click)="bulkMarkNotificationRead()">
        Marcar todas como lidas
      </button>
      
    </div>

    <!-- <div class="p-y-12 p-x-32" >
      <button mat-stroked-button color="primary" class="w-100" routerLink="/painel/notificacoes">
        Ver todas as notificações
      </button>
    </div> -->
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <div class="d-flex justify-content-center align-items-center">

    <img [src]="user | imagenPath" class="rounded-circle"  style="width: 35px; height: 35px " *ngIf="user?.Ruta_Imagen && user?.Ruta_Imagen !== ''"> 
    <img src="assets/images/no-image.jpg" class="rounded-circle" style="width: 35px; height: 35px "  *ngIf="!user?.Ruta_Imagen ||user?.Ruta_Imagen  === ''"> 
    &nbsp;
    <span class="font" style="font-size: 0.7em !important;"> {{user?.name}} </span>
  </div>

  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 250px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img [src]="user | imagenPath" class="rounded-circle" width="90" *ngIf="user?.Ruta_Imagen && user?.Ruta_Imagen !== ''"> 
          <img src="assets/images/no-image.jpg" class="rounded-circle" width="90" *ngIf="!user?.Ruta_Imagen ||user?.Ruta_Imagen  === ''"> 
         
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">{{user?.name}}</h6>
            <span class="f-s-14 d-block m-b-4">{{user?.role}}</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              {{user?.email}}
            </span>
          </div>
        </div>
      </div>
    
      <div class="p-y-12 p-x-32">
        <a
          routerLink='/autenticacao/login'
          mat-stroked-button
          color="primary"
          class="w-100"
          (click)="logout()"
          >Sair</a
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
