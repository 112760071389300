import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MaterialModule } from 'src/app/material.module';
import { BankCreditcardService } from 'src/app/services/bank-creditcard.service';
import { ErrorService } from 'src/app/services/error.service';
import { BankLogoPipe } from "../../../../pipe/bank-logo.pipe";
import { DrawersService } from 'src/app/services/drawers.service';
import { delay } from 'rxjs';

interface Bank {
  country_code: string,
  account_number: string,
  document_number: string,
  bank_code: string,
  branch_code: string,
  account_type: string,
}

interface ValidAccount {
  account_currency: string,
  country_code: string,
  account_number: string,
  document_number: string,
  bank_code: string,
  branch_code: string,
  account_type: string,
  beneficiary_name: string,
  document_type: string,
  message: string,
  valid: string,
}


@Component({
    selector: 'app-verify-account-drawer',
    standalone: true,
    templateUrl: './verify-account-drawer.component.html',
    styleUrl: './verify-account-drawer.component.scss',
    imports: [CommonModule, MaterialModule, ReactiveFormsModule, BankLogoPipe]
})
export class VerifyAccountDrawerComponent {


  @Input() data : any;
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('valid') valid!: ElementRef;

  myForm! : FormGroup;
  digit : any;  
  isLoading : boolean = false;
  showErrorLabel : boolean = false;
  close : boolean = false;
  bank : any;
  validAccount : ValidAccount;

  accounts: any[] = [
    { name: 'CONTA POUPANÇA', value: 'SAVINGS' },
    { name: 'CONTA CORRENTE', value: 'CHECKING' },
    { name: 'CONTA PAGAMENTO', value: 'PAYMENTS' },
    { name: 'CONTA FACIL', value: 'EASY' },
    { name: 'ENTIDADES PÚBLICAS', value: 'PUBLIC_ENTITY' }
  ];
  
  

  
  constructor(
              private fb : FormBuilder,
              private bankCreditcardService : BankCreditcardService,
              private errorService : ErrorService,
              private toastr: ToastrService,
              private drawerService : DrawersService


  )
   { 

    this.errorService.closeIsLoading$.pipe(delay(700)).subscribe( (emitted) => {if(emitted){this.isLoading = false;}});

    this.myForm = this.fb.group({
      account_number: [null, Validators.required], 
      account_type: [null, Validators.required], 
      document_number: [null, Validators.required], 
      branch_code: [null, Validators.required], 
    })

 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showErrorLabel = false;
    if(this.data ){
      this.myForm.reset();
      this.bank = this.data;
      this.patchForm(this.data)
      console.log('data bank', this.data);
    }

  }

  ngOnInit(): void {



  
  }

  patchForm(data:any){

    this.myForm.patchValue({
      account_number: data.account_number, 
      document_number: data.document, 
      branch_code: data.branch_code, 
    })

  }

  get f(){
    return this.myForm.controls;
  }

  onSave(){

    if ( this.myForm.invalid ) {
      this.myForm.markAllAsTouched();
      return;
    }

    const account_number = this.myForm.get('account_number')?.value;
    const account_type = this.myForm.get('account_type')?.value; 
    const document_number = this.myForm.get('document_number')?.value;
    const branch_code = this.myForm.get('branch_code')?.value;

    const body : Bank = {
       bank_code: this.bank.bankNumber, 
       account_number,
       branch_code,
       document_number,
       account_type,
       country_code: 'BR'
    }

    console.log(body);

    this.isLoading = true;

    this.bankCreditcardService.bankDataSelected$.emit(body);
    this.bankCreditcardService.validateBankAccount(body).subscribe( 
     ( {success, data, error} )=>{

   
    setTimeout(()=>{ this.isLoading = false}, 500);

       if(success ){
          if(data && data.data){
                 this.validAccount = data.data;
                 this.goToValid();
          }else if(error && error.errors){
               this.warningToast(error.errors.message)
          }
         }
     });
  }

  goToValid(){
    setTimeout( () => {
      this.valid.nativeElement.scrollIntoView(
      { 
        alignToTop: true,
        behavior: "smooth",
        block: "center",
      });
      }, 0);
  }

  closeDrawer(){
    this.close = true;
    setTimeout(()=>{
      this.drawerService.closeDrawerVerAccount();
    }, 100)
  }

  warningToast( msg:string){
    this.toastr.warning(msg, 'Rever!!', {
      positionClass: 'toast-bottom-right', 
      timeOut: 6000, 
      messageClass: 'message-toast',
      titleClass: 'title-toast'
    });
  }


  validField( field: string ) {
    const control = this.myForm.controls[field];
    return control && control.errors && control.touched;
}
 

}

