import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';
import { ValidateService } from 'src/app/services/validate.service';

const MODULES = [CommonModule, ReactiveFormsModule, MaterialModule];

@Component({
  selector: 'app-contact-us-modal',
  standalone: true,
  imports: [MODULES],
  templateUrl: './contact-us-modal.component.html',
  styleUrl: './contact-us-modal.component.scss'
})
export class ContactUsModalComponent {
  
  private fb = inject(FormBuilder)
  private authService = inject(AuthService);
  private validatorService = inject(ValidateService);
  private errorService = inject(ErrorService);
  public toastr = inject(ToastrService);
  public dialogRef = inject(MatDialogRef<ContactUsModalComponent>);

  myForm!: FormGroup;
  isLoading : boolean = false;
  backClose : boolean = false;
  phone : boolean = false;

  constructor()
  {
    (screen.width < 800) ? this.phone = true : this.phone = false;
            
    this.errorService.closeIsLoading$.pipe(delay(700)).subscribe( (emitted) => {if(emitted){this.isLoading = false;}});

    this.myForm = this.fb.group({
      fullName: [ '', [Validators.required] ],
      userEmail: [ '', [Validators.required, Validators.pattern(this.validatorService.emailPattern)] ],
      subject: [ '', [Validators.required] ],
      headquarter: [ '', [Validators.required] ],
    });
  }

  get f(){
    return this.myForm.controls;
  }

  onSave(){

    if ( this.myForm.invalid ) {
      this.myForm.markAllAsTouched();
      return;
    }

    const body = {
      ...this.myForm.value,
    }

    this.isLoading= true;
    this.authService.contactUs(body).subscribe( 
      ({success})=>{
        if(success){
          this.isLoading= false;
            this.successToast('Em breve entraremos em contato');
            this.dialogRef.close()
        }
      })

  }

 

  successToast( msg:string){
    this.toastr.success(msg, 'Sucesso!!', {
      positionClass: 'toast-bottom-right', 
      timeOut: 3500, 
      messageClass: 'message-toast',
      titleClass: 'title-toast'
    });
  }

  
  closeModal(){
    this.backClose = true;
    setTimeout( ()=>{ this.dialogRef.close() }, 400 )
  }
  
  validField( field: string ) {
    const control = this.myForm.controls[field];
    return control && control.errors && control.touched;
  }



}
