
<div class="w-100 pd-20" style=""  >
  
         <div class=" d-flex justify-content-end align-items-center ">
            <span class="material-symbols-outlined cursor" [ngClass]="close ? ' iconSelected':'iconDeselected' " (click)="closeDrawer()">close</span>
         </div>

        <div class="d-flex justify-content-center mt-30" >
            <span class="material-icons" style="font-size: 100px; color: rgb(244, 8, 47);" *ngIf="!bank?.filePath || bank?.filePath === '' "> account_balance  </span>
            <img [src]="bank | bankLogoPath " class="img-fluid" style="width:40%; height: auto;" *ngIf="bank?.filePath && bank?.filePath !== '' ">
            <!-- <img [src]="img " class="img-fluid" style="width:40%; height: auto;" *ngIf="bank?.filePath && bank?.filePath !== '' "> -->
        </div>

        <form [formGroup]="myForm" class="needs-validation mt-3 font fs-10 "  novalidate  [autocomplete]="false" (ngSubmit)="onSave()" >
            
            <div class="col-12 mt-30" >
                <label class="text w-100 fs-10" style="font-family: poppins-regular;"> Núm BANCO </label>
                  <div style="text-transform: uppercase; font-family: poppins-regular; font-size: 1.3em;" > {{ bank?.name }} &nbsp;  ({{bank?.bankNumber}})
                </div>
            </div> 
            
            <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Número do agencia <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="branch_code"   />
                <mat-error *ngIf="validField('branch_code')" class="error w-100"> Dado obrigatório </mat-error>    
                </mat-form-field>
            </div>

            <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Número de conta <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="account_number" />
                <mat-error *ngIf="validField('account_number')" class="error w-100"> Dado obrigatório </mat-error>    
                </mat-form-field>
            </div>

            <div class="col-12 mt-30" >

                <mat-label class="font f-w-600 m-b-8 d-block">Tipo de conta <b style="color: red;">*</b> </mat-label>

                <mat-form-field appearance="outline" class="w-100" color="primary"  >

                    <mat-select formControlName="account_type" style="width: 100%;" >
                    <mat-option *ngFor="let account of accounts" [value]="account.value" class="font fs-10">
                        &nbsp;&nbsp;{{ account.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="validField('account_type')" class="error w-100"> Dado obrigatório </mat-error>    
                </mat-form-field>
            </div>
     
 
         <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">CPF / CNPJ <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="document_number"   />
                <mat-error *ngIf="validField('document_number')" class="error w-100"> Dado obrigatório </mat-error>    
                </mat-form-field>
         </div>

            <div class="d-flex justify-content-end pd-10 mt-30">
                <button class="cancelButton" (click)="closeDrawer()"> Cancelar </button> &nbsp;&nbsp;
                <button type="submit" class="acceptButton" > Enviar</button>
            </div>
       
        </form> 




        <div class="loading-overlay" *ngIf="isLoading">
            <div class="spinner-grow " role="status" style="width: 50px; height: 50px;"  >
                <mat-spinner></mat-spinner>
            </div>
            </div> 

        <div class="flex justify-content-center align-items-center w-100 shadow pd-20" *ngIf="validAccount" >

            <span class="font fs-10 d-block w-100"> <b>Moeda:</b> {{validAccount?.account_currency}}  </span>
            <span class="font fs-10 d-block w-100"> <b>Codigo da país: </b> {{validAccount?.country_code}} </span>
            <span class="font fs-10 d-block w-100"> <b>Número da conta: </b>{{validAccount?.account_number}} </span>
            <span class="font fs-10 d-block w-100"> <b>CPF/CNPJ: </b>{{validAccount?.document_number }} </span>
            <span class="font fs-10 d-block w-100"> <b>Código banco: </b> {{validAccount?.bank_code }}</span>
            <span class="font fs-10 d-block w-100"> <b>Código agencia: </b>{{validAccount?.branch_code }} </span>
            <span class="font fs-10 d-block w-100"> <b>Tipo da conta: </b>{{validAccount?.account_type }} </span>
            <span class="font fs-10 d-block w-100"> <b>Nome titular: </b>{{validAccount?.beneficiary_name }} </span>
            <span class="font fs-10 d-block w-100"> <b>Tipo documento:</b> {{validAccount?.document_type }} </span>
            <span class="font fs-10 d-block w-100"> <b>Mensaje: </b>{{validAccount?.message }}</span>
            <span class="font fs-10 d-block w-100"> <b>Valido: </b> {{validAccount?.valid}} </span>

        </div>
        <div #valid></div>

</div>    