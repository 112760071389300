@if(items?.navCap){
<div mat-subheader class="nav-caption">
  {{ items?.navCap }}
</div>
}

@if(!items?.navCap && !items?.external && !items?.twoLines) {


<a  mat-list-item (click)="onItemSelected(items)" [ngClass]="{ 'mat-toolbar mat-primary activeMenu': items?.route ? router.isActive(items?.route, true) : false, expanded: expanded,  disabled: items?.disabled }" class="menu-list-item" >

  <i-tabler class="routeIcon" name="{{ items?.iconName }}" matListItemIcon ></i-tabler>
    <span class="hide-menu" style="font-family: poppins-regular;" >{{ items?.displayName }}
      <span class="childName" >{{ items?.childName }}</span>
      <span class="childrenName" >{{ items?.childrenName }}</span>
    </span>
    
    @if(items?.children && items?.children.length) {
      <span class="arrow-icon" fxFlex >
        <span fxFlex ></span> 
        <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'" > expand_more </mat-icon>
      </span>
      
    } 
    @if(items?.chip) {
      <span>  
        <span
        class="{{ item?.chipClass }} p-x-8 p-y-4 item-chip f-w-500 rounded-pill" >{{ items?.chipContent }}</span >
      </span>
    }
  </a>
<!-- </div> -->

}


<!-- children -->
@if(expanded) { 
  @for(child of item.children; track child) {
  <app-nav-item [item]="child" (click)="onSubItemSelected(child)" [depth]="depth + 1" >
  </app-nav-item>
  } }
