import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToTime',
  standalone: true
})
export class DecimalToTimePipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value !== 0) return '';

    const hours = Math.floor(value); // Extraer la parte entera (horas)
    const minutes = Math.round((value % 1) * 60); // Convertir la parte decimal en minutos

    // Formatear las horas y minutos en formato HH:mm
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

}