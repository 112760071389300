import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { getDataLS, getDataSS } from './storage';
import { filter, take } from 'rxjs';
import { LocalstorageService } from './services/localstorage.service';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {


  title = 'ArcanjoSaoRafael';
  isLoading : boolean = false;
  user: any;
  msg : string = '';
  phone : boolean = false; 
  remainingAttemps : number = 0;
  
  show400 : boolean = false;
  show401 : boolean = false;
  show404 : boolean = false;
  show429 : boolean = false;
  show500 : boolean = false;
  showBackDown : boolean = false;

  constructor(
                public router : Router,
                private localStorageService : LocalstorageService,
                private cookieService: CookieService,
                private authService : AuthService


){

  (screen.width <= 800) ? this.phone = true : this.phone = false;

  this.router.events.pipe(
    filter(event => event instanceof ActivationEnd),
    take(1)
    ).subscribe((event) => {
      const activationEndEvent = event as ActivationEnd; 
      const url = activationEndEvent.snapshot.url.toString(); // Obtener la URL como string
      const user = getDataSS('user');
    
      //si se trata de verificar el email no pido token ni nada
    if (!url.includes('verificar-email')) {

      //esto es el reload de session storage
      if( !user){
        this.router.navigateByUrl('/login');
        // const sessionId = getDataSS('sessionId'); 
        // if (sessionId) {
        //     this.cookieService.delete(`token_${sessionId}`, '/'); 
        //     sessionStorage.removeItem('sessionId'); 
        // }

      }
    } 
  });

}

ngOnInit(): void {

  this.localStorageService.loadInitialState();
  this.checkIfSSItsAllowed();
}  

//si o si necesito q el navegador acepte SS
checkIfSSItsAllowed(){
  if (typeof(Storage) === "undefined") {

      Swal.fire({
        icon: 'error',
        title: "Este aplicativo não pode ser executado neste navegador",
        text: "Atualize ou mude seu navegador",
        footer: "Desculpe o incômodo",
        allowOutsideClick: false,  
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed ) {
          this.router.navigateByUrl('/login')
        }
      });
  }
  
}



}
