import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, ViewChild } from '@angular/core';
import {
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexLegend,
  ApexStroke,
  ApexTooltip,
  ApexAxisChartSeries,
  ApexXAxis,
  ApexYAxis,
  ApexGrid,
  ApexPlotOptions,
  ApexFill,
  ApexMarkers,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { MaterialModule } from '../../../material.module';
import { TablerIconsModule } from 'angular-tabler-icons';
import { ErrorService } from 'src/app/services/error.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { getDataSS } from 'src/app/storage';
import moment, { Moment } from 'moment';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { DecimalToTimePipe } from "../../../pipe/decimal-to-time.pipe";
import * as authActions from '../../../shared/redux/auth.actions'
import { delay, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/redux/app.reducer';

interface month {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput:   
 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface revenueChart {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  marker: ApexMarkers;
}

@Component({
  selector: 'app-revenue-updates',
  standalone: true,
  imports: [NgApexchartsModule, MaterialModule, TablerIconsModule, FormsModule, ReactiveFormsModule, DecimalToTimePipe],
  templateUrl: './revenue-updates.component.html',
  styleUrl: './revenue-updates.component.scss',
  providers: [

        // { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        provideMomentDateAdapter(MY_FORMATS),
        // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  })

export class RevenueUpdatesComponent implements OnDestroy {

  @ViewChild('chart') chart: ChartComponent = Object.create(null);

  public revenueChart!: Partial<revenueChart> | any;
  private cdr = inject(ChangeDetectorRef);
  private errorService = inject(ErrorService);
  private store = inject(Store<AppState>);
  private statisticsService = inject(StatisticsService);
  // private toastr= inject(ToastrService);

  isLoading : boolean = false;
  days : any [] = [];
  series : any ;
  readonly date = new FormControl(moment());
  selected: string;
  user: any;
  propulsao: any;
  monthlyAmount : number = 0;
  monthlyWorkedHours : number = 0;
  private authSubscription : Subscription;
  
  
  
  constructor() {

    this.errorService.closeIsLoading$.pipe(delay(700)).subscribe( (emitted) => {if(emitted){this.isLoading = false;}});
  
    this.selected = moment().format('MM/YYYY'); 

    const user = getDataSS('user');

    if(user){
      this.user = user;
      this.getInitData(user.iduser);
    }

    this.authSubscription = this.store.select('auth').subscribe(
      ({propulsao})=>{
        this.propulsao = propulsao;
      })

    this.revenueChart = {

      series: [],

      chart: {
        type: 'bar',
        fontFamily: "'Plus Jakarta Sans', sans-serif;",
        foreColor: '#adb0bb',
        toolbar: {
          show: false,
        },
        height: 380,
        stacked: true,
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: [6],
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'all',
        },
      },

      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: 'rgba(0,0,0,0.1)',
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 500,
        tickAmount: 4,
        labels: {
                  formatter: function (val: number) {
                    return Math.round(val); // Redondear a números enteros
                  }
               }
      },
      xaxis: {
        categories: []
      },

      tooltip: {
        theme: 'dark',
        fillSeriesColor: false,
      },
    };
  }


  getInitData(iduser: string) {

    const currentDate = moment().format('YYYY-MM-DD');
  
    this.statisticsService.getWorkedHoursByMonthAndYear(iduser, currentDate).subscribe(
      ({ success, workedHours }) => {

        if (success) {
          
          // Comenta o ajusta la línea de loading según sea necesario
          setTimeout(() => { this.isLoading = false; }, 700);

        // Obtener el mes y el año actuales
          const month = moment(currentDate).month() + 1; // moment.js usa índice base 0, por lo que sumamos 1
          const year = moment(currentDate).year();
          const days= this.getDaysInMonth( month, year );

          this.setStatistics(workedHours, days);
        }
      }
    );
  }
  
  setStatistics( workedHours:any , days:string[] ){

        this.monthlyAmount = 0;
        this.monthlyWorkedHours = 0;


        // Inicializa el array de montos con ceros
        const amounts = new Array(days.length).fill(0); // Crea un array de ceros con la longitud de los días

        // Supongamos que workedHours es un array de objetos con estructura { date: 'YYYY-MM-DD', amount: <monto> }
        workedHours.forEach( (element:any) => {
          
          const day = moment(element.visitDate).date(); // Obtener el día del mes de la fecha
          
          let amountDonated = 0;
          
          let monthlyWorkedHours = 0
          
          element.WorkedhoursDonors.forEach((donor: any) => {
            amountDonated += parseFloat(donor.amountDonated) || 0; // Sumar el monto donado
            // monthlyWorkedHours += parseFloat(donor.workedHours) || 0; // Sumar el monto donado
          });

                      // Sumar horas trabajadas directamente de `workedHours` si corresponde
          if (element.workedHours && Array.isArray(element.workedHours)) {
            element.workedHours.forEach((hours: number) => {
              monthlyWorkedHours += hours; // Sumar las horas trabajadas en formato decimal
            });
          }

          this.monthlyAmount += amountDonated;
          this.monthlyWorkedHours += monthlyWorkedHours;
        

          amounts[day - 1] += amountDonated; // Sumar el monto al índice correspondiente (day - 1)
        });

        const series = [
          {

            name: 'Doações do dia',
            data: amounts,
            color: '#5D87FF', 
          },
        
        ];

    // Construir el eje x
      const xaxis = { categories: days.map(day => day.toString()) }; // Convertir días a strings

        console.log('days', days);

        setTimeout(() => {
          this.revenueChart.series = series;
          this.revenueChart.xaxis = xaxis;
          this.cdr.detectChanges();
        }, 1000); // Un pequeño retraso puede ayudar

  }
  

  getDaysInMonth(month: number, year: number): string[] {
    // Obtener el último día del mes (los meses son indexados desde 0: enero es 0, febrero es 1, etc.)
    const daysInMonth = new Date(year, month, 0).getDate();
    
    // Crear un array con los días
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());

  
    return daysArray;
  }


  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    ctrlValue.date(1);
    const formattedDate = ctrlValue.format('YYYY-MM-DD');
    datepicker.close();

    this.statisticsService.getWorkedHoursByMonthAndYear(this.user.iduser, formattedDate).subscribe(
      ({ success, workedHours }) => {

        if (success) {
          
          setTimeout(() => { this.isLoading = false; }, 700);

          console.log('formattedDate', formattedDate);

        // Obtener el mes y el año actuales
          const month = moment(formattedDate).month() + 1; // moment.js usa índice base 0, por lo que sumamos 1
          const year = moment(formattedDate).year();
          const days= this.getDaysInMonth( month, year );

          this.setStatistics(workedHours, days);
        }
      }
    );
  }


  ngOnDestroy(): void {

    if(this.authSubscription){
      this.authSubscription.unsubscribe();
    }
  }



  
}

