<ng-container  *ngIf="user && user.role !== 'user'">
<div class="d-flex justify-content-around align-items-center w-100 ">


    @for(topcard of topcards; track topcard.color) {
    <div class="col-3">
      <mat-card class="text-center bg-light-{{ topcard.color }}  shadow cursor" >
        <mat-card-content (click)="navigateToPanel(topcard.title)">
            <img [src]="topcard.img" alt="users" width="40" class="rounded-circle"  />
            <h4 class="mat-subtitle-2 fs-10  text-{{ topcard.color }} m-t-8">
                {{ topcard.title }}
            </h4>
            <h6 class="m-t-4 mat-subtitle-1 f-w-600 text-{{ topcard.color }} m-t-8">
                {{ topcard.subtitle }}
            </h6>
        </mat-card-content>
      </mat-card>
    </div>
    }
  </div>


<div class="row mt-50">

  <div class="col-sm-12 col-xs-12 col-md-6 font fs-10">
    <div class="main-card mb-3 card ">
      <div class="card-header font fs-12"> <b>Pedidos de registro</b>
          </div>
      
          <div class="table-responsive pd-20">

        <table class="align-middle mb-0 table table-borderless table-striped table-hover fs-10 font" >
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th>Nome</th>
              <th >Data</th>
            </tr>
          </thead>
          <tbody *ngIf="reqRegister.length > 0; else noData">
            <tr *ngFor="let request of reqRegister; let i = index" [routerLink]="['editar-usuario/', request.iduser]" class="font fs-12" >
              <td >{{ i + 1 }}</td>
              <td>
                  <a class="selected fs-11"> {{ request.Nome_Completo }} </a> 
              </td>
              <td >{{ request.date | date }}</td>
            </tr>
          </tbody>
          <ng-template #noData >
            <tr class="mt-3">
              <td colspan="3" class="text-center font fs-10" style="height: 40px; color: blue;">Sem pedidos de registros</td>
            </tr>
          </ng-template>
        </table>
      </div>

    </div>
  </div>

  <div class=" col-sm-12 col-xs-12 col-md-6 font fs-10">
    <div class="main-card mb-3 card">
      <div class="card-header font fs-12"> <b>Congregatio atualizada</b>
    
      </div>
      <div class="table-responsive pd-20">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover font fs-10" >
          <thead>
            <tr>
              <th>Data</th>
              <th>Registros atualizados</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="congregatio">
              <tr class="font fs-12" >
                <td>{{ congregatio.timestamp | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                <td>
                    <span style="background-color: red; color: white; border-radius: 10px; font-size: 1.1em; padding: 5px 12px 5px 12px;">
                          {{ congregatio.updatedUsers}}
                  </span>
                  </td>
              </tr>
            </ng-container>
            
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  
  
  <div class="col-sm-12 col-12 col-md-6 font-fs-10 mt-50">
    <div class="main-card mb-3 card">
      <div class="card-header font fs-12"> <b>Unidades atualizadas</b>
          </div>
      <div class="table-responsive pd-20">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover fs-10 font" >
          <thead>
            <tr>
              <th> Data </th>
              <th> Unidades atualizados </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="congregatio">
              <tr class="font fs-12" >
                <td>{{unitTimeStamp?.timestamp | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                <td>
                    <span style="background-color: green; color: white; border-radius: 10px; font-size: 1.1em; padding: 5px 12px 5px 12px;">
                      {{ unitTimeStamp?.updatedUnits}}
                    </span>
                </td>
              </tr>
            </ng-container>
            
          </tbody>
        </table>
      </div>
      
    </div>
  </div>



</div>

</ng-container>

<ng-container  *ngIf="user && user.role === 'user'">
  <div class="row">
    <div class="col-12">
      <app-revenue-updates></app-revenue-updates>
    </div>
  </div>  
</ng-container>

  